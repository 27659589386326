<template>
  <b-modal id="add-client" centered title="Nouveau Client" ref="modal"  title-class="font-20"
           hide-footer hide-header-close @shown="create">
    <form @submit.prevent="addClient">
    <div class="row p-4">
      <div class="col-xl-10 m-auto font-size-14">
          <div class="form-group  mt-3 mb-0">
            <label class="mb-2">Nom Complet :</label>
            <b-form-input  type="text" v-model="client.fullname" ></b-form-input>
          </div>
        <div class="form-group  mt-3 mb-0">
          <label class="mb-2">Email :</label>
          <b-form-input  type="email" v-model="client.email"></b-form-input>
        </div>
        <div class="form-group  mt-3 mb-0">
          <label class="mb-2">Mot de passe :</label>
          <b-form-input  type="password" v-model="client.password"></b-form-input>
        </div>
          <div class="form-group  mt-3 mb-0">
            <label class="mb-2">Nom de la compagnie :</label>
            <b-form-input  type="text" v-model="client.company_name"></b-form-input>
          </div>
          <div class="form-group  mt-3 mb-0">
            <label class="mb-2">Type de paiement :</label>
            <b-form-select :options="types" class="form-select" v-model="client.payment_type" ></b-form-select>
          </div>
        <div class="form-group  mt-3 mb-0">
          <label class="mb-2">Price per Device :</label>
          <b-form-input  type="text" v-model="client.price_per_device"></b-form-input>
        </div>

      </div>


    </div>
    <b-button type="submit" class="m-0  text-center w-100" variant="dark" style="border-radius:0 0 20px 20px;font-size: 20px ">
      Ajouter
    </b-button>
    </form>

  </b-modal>
</template>

<script>
export default {
  name: "addClient",
  data(){
    return{
      client:{},
      types: [
        { value: 'monthly', text: 'mensuel' },
        { value: 'bi-weekly', text: 'bihebdomadaire' },
        { value: 'weekly', text: 'hebdomadaire' },
      ]
    }
  },
  created(){
    this.client = {}
  },
  methods: {
    create(){
      this.client = {}
    },
    hideModal() {
      this.$refs['modal'].hide()
    },
    addClient()
    {
           this.$http
          .post('clients',this.client)
          .then(response => {
            this.$emit('refresh-add',response.data)
            this.$alertify.success(" success")
            console.log(response.data)
            this.hideModal()
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error("Error");
          })
    },

  }
}

</script>

<style scoped>
/deep/  .modal-body {
  padding: 0rem !important;
}
/deep/ .modal-title{
  margin: auto;
  font-size: 30px;
  color: white;
  font-weight: bold;

}
/deep/ .modal-header .close{
  margin: 0;
}

/deep/ .modal-content{
  background-color: #393e46;
  border-radius: 20px!important;
}

label{
  color: #eee;
  margin:auto;
}



input[type='text'], input[type='file'], input[type='textarea']{
  border: 0;
  background-color: white;
  color: #222831;
  padding: 10px 10px;
}






</style>