<template>
  <b-modal id="delete" centered  ref="modal"  title-class="font-22"
           hide-footer hide-header>
    <div class="card">
      <div class="card-body m-auto row mx-0 font-size-14" >
        <!--        <div class="loading" v-if="wait">Loading&#8230;</div>-->
        <div class="col-xl-10 mx-auto text-center ">

          <h4 class="my-5">Voulez-vous vraiment le supprimer ?</h4>
          <div class="row mx-0 mb-4 justify-content-center">
            <button class="col-xl-2 btn me-3" @click="deleteItem">Oui</button>
            <button class="col-xl-2 btn " @click="hideModal">Non</button>

          </div>
        </div>

      </div>


    </div>

  </b-modal>
</template>

<script>
export default {
  name: "deleteConfirmation",
  props:['type','id'],
  data(){
    return{

    }
  },
  methods: {
    hideModal() {
      this.$refs['modal'].hide()
    },
    deleteItem()
    {

      this.$http
          .delete(this.type+'/'+this.id)
          .then(() => {
            this.$emit('refresh')
            this.$alertify.success(" success")
            this.hideModal()
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error("Error");
          })
    },

  }
}

</script>

<style scoped>
/deep/  .modal-body{
  padding: 0rem !important;
}
/deep/ .modal-content{
  background-color: #fff;
  border-radius: 20px!important;
}
.card-body{
  min-height: 10rem;
}
.card{
  background: transparent;
  border: none;
}

.btn{
  color:  #fff;
  border-radius: 12px;
  background-color: #00006e;
}



</style>