<template>
    <main class="row mt-5 mx-0">
      <div class="col-xl-12 m-auto">
        <div class="row mb-4 mx-0">
          <h2 class="col-xl-11 col-8 ">Clients</h2>
          <button class="col-xl-1 col-4 btn btn-dark float-right" v-b-modal.add-client>Ajouter</button>
        </div>
        <div  style="border-radius:12px;border: solid 3px #32394e">
          <table class="text-center">
            <thead>
            <tr class="py-1">
              <th scope="col">Nom Complet</th>
              <th scope="col">Compagnie</th>
              <th scope="col">Date limite</th>
              <th scope="col">Dernier paiement</th>
              <th scope="col">Type de paiement</th>
              <th scope="col">Montant à payer</th>
              <th scope="col" style="min-width:220px" >Licence</th>
              <th scope="col">Price Per Device</th>
              <th scope="col"  ></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,index) in clients" :key="data.id" >
              <td data-label="Nom Complet" :class="{'bg-danger':data.is_not_paid}">{{data.fullname}}</td>
              <td data-label="Compagnie" :class="{'bg-danger':data.is_not_paid}">{{data.company_name}}</td>
              <td data-label="Date limite" :class="{'bg-danger':data.is_not_paid}"> {{new Date(data.due_time).toLocaleString()}}</td>
              <td data-label="Dernier paiement" :class="{'bg-danger':data.is_not_paid}"> <span v-if="data.last_payment">{{data.last_payment}}</span><span v-else> - </span></td>
              <td data-label="Type de paiement" :class="{'bg-danger':data.is_not_paid}"> {{types[data.payment_type]}}</td>
              <td data-label="Montant à payer " :class="{'bg-danger':data.is_not_paid}"> {{data.amount}}</td>
              <td data-label="License " style="min-width:220px" :class="{'bg-danger':data.is_not_paid}"> {{data.license}}</td>
              <td data-label="Price Per Device" :class="{'bg-danger':data.is_not_paid}"> {{data.price_per_device}}</td>
              <td data-label="Actions"   :class="{'bg-danger':data.is_not_paid}">
                <button type="button" v-if="data.is_not_paid" @click="Paid(data)" class="btn btn-light mb-1 btn-sm">Paid</button>
               <div class="d-flex justify-content-end">
                 <i class="fa fa-edit text-white mx-0 px-1" @click="showEdit(data)"></i>
                 <i class="fa fa-ban mx-0 px-1" :class="{'text-white':data.status == 'blocked','text-black':data.status == 'unblocked'}" @click="banClient(index)"  :title="data.status == 'blocked'?'Débloquer':'Bloquer'"></i>
                 <i class="fa fa-trash text-danger mx-0 px-1" :class="{'text-white':data.is_not_paid}"  @click="showDelete(data.id)"></i>
               </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>



      </div>
      <add-client v-on:refresh-add="refreshAdd"/>
      <edit-client :client_p="client" v-on:refresh-edit="refreshEdit"/>
      <paid-confirm :user="user" v-on:refresh-paid="refresh_paid"/>
      <delete-confirmation type="clients" :id="selected_id"  v-on:refresh="refreshDelete"/>

    </main>

</template>

<script>

import AddClient from "../components/addClient";
import EditClient from "../components/editClient";
import PaidConfirm from "../components/paidConfirm";
import DeleteConfirmation from "@/components/deleteConfirmation";

export default {
  name: "Clients",
  components: {DeleteConfirmation, PaidConfirm, EditClient, AddClient},
  data(){
    return{

      selected_index: 0,
      selected_id:'',
      client: {},
      user: {},
      clients:[],
      types:{
        "monthly":"mensuel","bi-weekly":"bihebdomadaire","weekly":"hebdomadaire"
      }
    }
  },

  created () {

    this.loadClients();
  },


  methods:{

    showEdit(g){
      this.client= g
      this.$root.$emit('bv::show::modal', 'edit-client')

    },
    showDelete(id){
      this.selected_id= id
      this.$root.$emit('bv::show::modal', 'delete')
    },
    refreshDelete(){
      let index = this.clients.findIndex(el => el.id === this.selected_id)
      this.clients.splice(index,1)
    },
    banClient(index){
      this.$http
          .post('clients/'+this.clients[index].id+'/block')
          .then(() => {
            if(this.clients[index].status == 'blocked')  this.clients[index].status = 'unblocked'
            else this.clients[index].status = 'blocked'
            this.$alertify.success(" success")
          })
          .catch(error => {
            console.log(error)
            this.$alertify.error("Error");
          })
    },
    refresh_paid(data){
      let index = this.clients.findIndex(c => c.id === this.user.id)
      this.clients[index] = data
      this.$forceUpdate();
    },
    refreshEdit(t){
      console.log(t)
      let index = this.clients.findIndex(c => c.id === this.client.id)
      this.clients[index]= t
      this.$forceUpdate();


    },
    Paid(user){
      this.user= user
      this.$root.$emit('bv::show::modal', 'paid')
    },
    refreshAdd(data){

      this.clients.unshift(data)
      this.$forceUpdate();

    },
    loadClients()
    {
      this.$http
          .get('clients')
          .then(response => {
            this.clients = response.data;
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}

</script>

<style scoped  src="@/assets/table.css"></style>
<style scoped>
tr td{
  color: white;
  background-color: green;
}


</style>